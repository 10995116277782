<template>
   <div class="csLandingHome">
     <CustomersLog v-if="!isdl"></CustomersLog>
     <CustomerDetails v-if="isdl"></CustomerDetails>
   </div>
</template>

<script>
export default {
  name: "csLandingHome",
  data() {
    return {

    }
  },
  computed: {
    isdl() {
      if (this.$store.state.users.CsUser) {
        return true
      }else {
        return false
      }
    }
  },
  watch: {
    '$store.state.users.CsUser': function (n, o) {

    }
  },
  created() {
    this.$store.commit('isShow/upBottom', false)
  },
  components: {
    CustomersLog: () => import('@/views/accepts/customer/customersLog'),
    CustomerDetails: () => import('@/views/accepts/customer/customerDetails'),
  }
}
</script>

<style scoped >
.csLandingHome {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>